<template>
  <div class="grid grid4">
    <ProjectCard
      :project="null"
    />

    <ProjectCard
      v-for="project in projects"
      :key="project.id"
      :project="project"
    />
  </div>
</template>

<script>
import { getAll } from '@/api/providers/projects';
import ProjectCard from '@/components/Projects/Card.vue';

import setRouteNameForModule from '@/helpers/route-name';

export default {
  name: 'ProjectsIndex',

  components: {
    ProjectCard,
  },

  data: () => ({
    projects: [],
  }),

  beforeMount() {
    setRouteNameForModule('Projects', 'Overzicht');
  },

  mounted() {
    this.loadProjects();
  },

  methods: {
    loadProjects() {
      getAll()
        .then((projects) => {
          this.projects = projects
        })
        .catch((error) => { if (typeof error.default === 'function') error.default() })
    },
  },
};
</script>

<style scoped>
.ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
}
</style>