<template>
  <router-link
    class="project-card grid-item mb-4"  
    :to="link"
  >
    <div
      class="card card-background align-items-start h-100"
      :class="{
        'card-background-mask-dark': project != null,
        'card-background-mask-primary': project == null
      }"
    >
      <div
        class="d-block w-100"
      >
        <div
          class="full-background cursor-pointer"
          :style="{
            backgroundImage: `url(${ backgroundImage })`
          }"
        >
        </div>
        <div 
          class="card-body"
        >
          <div 
            class="float-end" 
          >
            <h6
              v-if="project"
              class="text-white mb-0"
              style="font-size: 42px; line-height: 1em"
            >
              {{ project.projectItemCount.toString().padStart(2, '0') }}
            </h6>
            <span
              v-else
              class="text-white material-icons" 
              style="font-size: 60px;"
            >
              add_circle_outline
            </span>
          </div>

          <div class="d-flex">
            <div>
              <h5
                class="text-white mb-0"
              >
                {{ project?.title ?? "Nieuw" }}
              </h5>
              <p
                class="text-white text-sm"
              >
                {{ project?.subTitle ?? '&nbsp;' }}
              </p>
            </div>
          </div>
        </div>
      </div>
  </div>
</router-link>
</template>

<script>
import { parseDateTimeRange } from '@/helpers/parsers';
import { getDateString } from '@/helpers/datetime'

export default {
  name: 'ProjectCard',

  props: {
    project: {
      type: Object,
      required: true
    }
  },

  computed: {
    link() {
      return this.project
        ? {
          name: 'project-items',
          params: {
            projectId: this.project.id,
          },
        }
        : {
          name: 'new-project',
        };
    },
    backgroundImage() {
      return this.project
        ? this.project.visualImage
        : '/assets/img/projects/new-project-tile-bg.jpg'
    },
    dateCreateString() { return getDateString(new Date(this.publishDateTime)) },
    datePinnedString() {
      return this.pinnedDate instanceof String && this.pinnedDate !== ''
        ? getDateString(new Date(this.this.pinnedDate))
        : '';
    }
  },

  methods: {
    parseDateTimeRange,
  },
};
</script>

<style scoped lang="scss">
.news-card {
  &:nth-child(n + 5) {
    margin-top: var(--bs-gutter-x);
  }

  &__image {
    object-fit: cover;

    &-container {
      height: 200px;
    }
  }

  &__description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  &__reactions p {
    margin-bottom: 0;
  }
}</style>
